import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
// import {  } from 'gsap/SplitText'
gsap.registerPlugin(ScrollTrigger, SplitText)

class App {
  constructor() {
    this.createScrollAnimations()
  }

  createScrollAnimations() {
    ScrollTrigger.defaults({
      toggleActions: 'play reverse play reverse',
      scroller: '.container',
      start: 'center 85%'
      // markers: true
    })

    gsap.to('.one .content h1', {
      y: -30,
      opacity: 1,
      duration: 0.6,
      delay: 0.8,
      ease: 'expo'
    })

    gsap.from(['.two .content p', '.capability_item', '.work_item'], {
      scrollTrigger: '.two',
      y: 10,
      opacity: 0,
      duration: 0.8,
      ease: 'expo',
      stagger: 0.15
    })

    gsap.from(['.three .content p', '.contact_item'], {
      scrollTrigger: '.three',
      y: 10,
      opacity: 0,
      duration: 0.8,
      ease: 'expo',
      stagger: 0.2
    })

    gsap.fromTo(
      '.bg',
      {
        opacity: 0
      },
      {
        scrollTrigger: '.three',
        opacity: 0.4,
        ease: 'expo',
        duration: 1
      }
    )

    // gsap.to('.world__image', {
    //   scale: 14,
    //   transformOrigin: '50.5% 17.1%',
    //   duration: 10,
    //   delay: 2,
    //   ease: 'expo.inOut'
    // })
  }

  // moveToolTip(e) {
  //   gsap.to('.planet_first_content', {
  //     left: e.pageX + 10,
  //     top: e.pageY + 10,
  //     duration: 0.5
  //   })
  // }

  // createSubtitleAnimations() {
  //   this.trigger = document.querySelector('.planet_first_trigger')
  //   this.trigger.addEventListener('mouseenter', () => {
  //     if (this.animating) return
  //     this.showSubtitleAnimation()
  //   })

  //   let content = document.querySelector('.planet_first_content')
  //   this.words = new SplitText(content, { type: 'words' }).words
  //   gsap.set(this.words, { display: 'none' })
  //   // this.trigger.addEventListener('mouseleave', () =>
  //   //   this.hideSubtitleAnimation()
  //   // )
  // }

  // showSubtitleAnimation() {
  //   this.animating = true
  //   document.addEventListener('mousemove', this.moveToolTip)
  //   let tl = gsap.timeline()
  //   tl.fromTo(
  //     '.planet_first_content',
  //     { scale: 0 },
  //     {
  //       scale: 1,
  //       duration: 1,
  //       ease: 'expo.inOut'
  //     }
  //   )

  //   this.words.forEach((word, i) => {
  //     tl.set(word, {
  //       display: 'block'
  //     }).set(
  //       word,
  //       {
  //         display: 'none'
  //       },
  //       i !== this.words.length ? '+=0.16' : '+=0.5'
  //     )
  //   })
  //   tl.to('.planet_first_content', {
  //     scale: 0,
  //     duration: 1,
  //     ease: 'expo.inOut'
  //   }).call(() => {
  //     document.removeEventListener('mousemove', this.moveToolTip)
  //     this.animating = false
  //   })

  // this.words.forEach((word) => {
  //   let tl = gsap.timeline()
  //   tl.set(word, {
  //     display: 'block'
  //   })
  // })
  // tl.to('.planet_first_content', {
  //   display: 'block'
  // })

  // words.forEach((word) => {
  //   this.tl
  //     .fromTo(
  //       word,
  //       {
  //         autoAlpha: 0,
  //         left: '50%'
  //       },
  //       {
  //         autoAlpha: 1
  //       }
  //     )
  //     .to(word, { autoAlpha: 0 })
  // })
  // }

  // hideSubtitleAnimation() {
  //   this.tl.pause()
  // }
}

new App()
